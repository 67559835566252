.Dropzone {
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  margin: 5px;
  padding: 5px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 48px;
  width: 48px;
}

.FileInput {
  display: none;
}
