html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}

div .banner {
  background-color: #dddddd;
  flex: 0 0 auto;
  padding: 5px;
}

body,
html,
iframe,
#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  border: 0;
}

#image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #000000;
}

#video {
  position: block;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
}

#iframe {
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}

input[type="radio"] {
  display: none;
}
div input[type="radio"]:checked + label {
  background-color: lightgray;
}
label {
  display: block;
  background: #ffffff;
  color: #000000;
  border: 0px;
  padding: 0px;
  margin: 0 0 0 0;
  text-align: left;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin-bottom: 1px;
}

.tab-list-item {
  display: inline-block;
  flex: 0 0 auto;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: default;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.flex-column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: stretch;
}

.flex-row {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  overflow: auto;
  align-items: stretch;
}

.flex-column-fixed {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.flex-row-fixed {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

@media print {
  .songsheet-print {
    column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2;
  }

  .print-no-break {
    break-inside: avoid;
  }

  body,
  html,
  iframe,
  #root {
    width: auto;
    height: auto;
  }
}

.media_column_buttons {
  width: 80px;
}

.media_column_filename {
  flex: 1;
}

.media_column_date,
.media_column_size,
.media_column_lastPlayed {
  width: 90px;
  justify-content: end;
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
}

.form-group label {
  width: 150px;
}

.LinkButtonContainer {
  display: inline-flex;
  flex-direction: row;
}

.LinkButton {
  width: 140px;
  height: 140px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: 1px;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 10px;
}

.LinkButton:hover {
  background-color: #fff;
  box-shadow: 0px 15px 20px #aaaaaa;
  color: #000;
  transform: translateY(-7px);
}

.LinkButton img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.LinkButton:hover img {
  transform: translateY(0px);
}

.LinkButton label {
  padding-top: 5px;
  height: 10px;
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.build-text {
  font-size: 10px;
  margin-left: auto;
  padding-right: 5px;
  color: "gray";
}

.QueueItemIcon {
  width: 12px;
  height: 12px;
  margin: 3px;
}

.opacity20 {
  opacity: 0.3;
}
