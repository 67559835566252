.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  /*height: 50px;*/
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
